<template>
  <base-modal-view :title="modalTitle" :message="modalMessaage" @cancel="cancelClicked">
    <p :class="[requestStatus ? 'text-success' : 'text-danger']" class="text-center" v-html="requestMessage"></p>
    <div class="mx-auto spinner-border" role="status" v-if="requestLoading">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="mx-auto text-center" role="status" v-if="requestLoading">
      <div>Loading...</div>
      <div>This may take a few minutes</div>
    </div>
    <div class="text-center col" v-if="!requestLoading && invalidVin">
      <primary-button
        @click="submitClicked($event, true)"
        :disabled="requestLoading"
        title="Request A new PPSR"
        style="margin-left: auto; margin-right: auto"
      />
    </div>
    <div class="text-center col" v-else-if="!requestLoading && !requestStatus">
      <primary-button
        @click="submitClicked()"
        :disabled="requestLoading"
        title="Request Again"
        style="margin-left: auto; margin-right: auto"
      />
    </div>
    <div v-if="requestStatus && !requestLoading" class="container">
      <div class="row">
        <div class="text-center col">
          <primary-button
            @click="requestPPSRPDF()"
            :disabled="requestLoading"
            title="Download PDF"
            style="margin-left: auto; margin-right: auto"
          />
        </div>
        <div class="text-center col">
          <primary-button
            @click="submitClicked($event, true)"
            :disabled="requestLoading"
            title="Request a new PPSR"
            style="margin-left: auto; margin-right: auto"
          />
        </div>
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { orderPPSR, orderPPSRPDF } from "@/api/Form.js";

export default {
  name: "PpsrFormModal",
  components: { PrimaryButton, BaseModalView },
  props: {
    formId: {
      type: Number,
      required: true
    },
    form: {
      type: Object
    }
  },
  emits: ["update:form"],
  data: function () {
    return {
      requestLoading: false,
      requestStatus: null,
      requestPDFStatus: null,
      requestMessage: "",
      requestDate: null,
      requestDateUpdated: null,
      loading: false,
      status: false,
      PDFStatus: false,
      message: "",
      invalidVin: false
    };
  },
  mounted: function () {
    this.submitClicked();
  },
  methods: {
    submitClicked: function (event, newRequest = false) {
      this.requestLoading = true;
      this.requestStatus = null;
      this.requestMessage = "";
      this.invalidVin = false;
      orderPPSR(this.formId, newRequest)
        .then(response => {
          this.requestStatus = true;
          let data = response?.data;
          this.$emit("update:form", response?.data?.form);
          this.requestDate = data?.form?.ppsr?.request_date;
          this.requestDateUpdated = data?.form?.ppsr?.request_date_updated;
          let date = this.requestDate;
          let dateUpdated = this.requestDateUpdated;
          if (date) {
            date = new Date(date);
          }
          if (dateUpdated) {
            dateUpdated = new Date(dateUpdated);
          }
          if (dateUpdated) {
            // Convert to date object
            this.requestMessage = `A PPSR for this VIN HAS ALREADY been requested, download the PPSR PDF below, or request a new one. <br /><br /> <b>Request date:</b> <br /> ${date} <br /> <b>Request date updated:</b> <br /> ${dateUpdated}`;
          } else {
            this.requestMessage = `Successfully requested a PPSR, download the PPSR PDF below, or request a new one.`;
          }
        })
        .catch(error => {
          this.requestStatus = false;
          this.requestMessage = error?.response?.data?.detail;
          if (error?.response?.status === 409) {
            // Do something here
            this.invalidVin = true;
          }
        })
        .finally(() => {
          this.requestLoading = false;
        });
    },
    requestPPSRPDF: function () {
      this.requestLoading = true;
      setTimeout(() => {
        orderPPSRPDF(this.formId)
          .then(response => {
            this.requestPDFStatus = true;
            let pdf = response.data?.pdf_download;
            this.requestMessage = `PDF is being downloaded.`;

            let filename = "ppsr_pdf";
            // this.modalCancelled();
            pdf = `data:application/pdf;base64,${response.data?.pdf_download}`;

            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([pdf], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            // const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = pdf;
            link.download = filename + ".pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              // window.URL.revokeObjectURL(data);
            }, 100);
          })
          .catch(error => {
            this.requestPDFStatus = false;
            console.log(error.response?.data?.detail);
            this.requestMessage = "PPSR PDF has generated, click Download PDF to retrieve it now.";
            // this.modalCancelled();
          })
          .finally(() => {
            this.requestLoading = false;
          });
      }, 2000);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  computed: {
    modalTitle: function () {
      let title = "Request PPSR";
      return title;
    },
    modalMessaage: function () {
      let msg = "PPSR is being requested.";
      if (!this.requestLoading) {
        msg = "";
      }
      return msg;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
