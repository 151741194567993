<template>
  <base-modal-view :title="editingBodyDamage ? 'Update Damage' : 'Add Damage'" @cancel="cancelClicked">
    <form class="flex-column" @submit.prevent="submitClicked">
      <label class="text-field-title margin-top-05 margin-bottom-025" v-show="bodyDamage.description"
        >Description</label
      >
      <input
        autocomplete="do-not-autofill"
        v-model="bodyDamage.description"
        class="text-field"
        type="text"
        placeholder="Description"
        required
      />
      <label class="text-field-title margin-top-05 margin-bottom-025">Cost</label>
      <input
        autocomplete="do-not-autofill"
        v-model="bodyDamage.cost"
        class="text-field"
        type="number"
        placeholder="Cost"
        required
      />
      <label class="text-field-title margin-top-05 margin-bottom-025">Type</label>
      <select class="dropdown-field" required v-model="bodyDamage.is_mechanical">
        <option value="">-- Body Or Mechanical --</option>
        <option value="false">Body</option>
        <option value="true">Mechanical</option>
      </select>
      <primary-button class="margin-top" title="Submit" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

export default {
  name: "BodyDamageModal",
  components: { PrimaryButton, BaseModalView },
  props: ["editingBodyDamage"],
  data: function () {
    return {
      bodyDamage: {
        url: null,
        description: "",
        cost: "",
        is_mechanical: ""
      }
    };
  },
  methods: {
    submitClicked: function () {
      this.$emit("createBodyDamage", this.bodyDamage);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  mounted: function () {
    if (this.editingBodyDamage) {
      this.bodyDamage = this.editingBodyDamage;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
