<template>
  <base-modal-view title="Dealer Packs" @cancel="cancelClicked">
    <table class="results-table margin-top">
      <tr class="header">
        <th>Pack Name</th>
        <th>Document</th>
      </tr>
      <tr class="data-row" v-for="pack in dealerPacks" v-bind:key="pack.id">
        <td>{{ pack.description }}</td>
        <td><tiny-button title="Document" type="button" v-on:click="goToPack(pack)" /></td>
      </tr>
    </table>
  </base-modal-view>
</template>

<script>
import TinyButton from "../../Buttons/v2/TinyButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { fetchDealerPacks } from "@/api/v2/InternalDocuments.js";

import { mapActions } from "vuex";

export default {
  name: "AllDealerPacksModal",
  components: { TinyButton, BaseModalView },
  data: function () {
    return {
      dealerPacks: []
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    goToPack(pack) {
      let route = this.$router.resolve({
        name: "DealerPack",
        params: { id: pack.id }
      });
      window.open(route.href, "_blank");
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  mounted: function () {
    fetchDealerPacks()
      .then(response => {
        this.dealerPacks = response;
      })
      .catch(error => {
        this.addError(error);
      });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
