<template>
  <div>
    <div id="indicator" :style="{ backgroundColor: getBackgroundColour() }">
      <img :src="getIcon()" />
      <p class="body-2 margin-left-025">{{ alertTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusAlert",
  props: {
    alertLevel: {
      type: String,
      default: "danger"
    },
    alertTitle: {
      type: String,
      default: ""
    }
  },
  methods: {
    getIcon: function () {
      switch (this.alertLevel) {
        case "danger":
          return "/assets/img/icon-danger.svg";
        case "warning":
          return "/assets/img/icon-warning.svg";
        case "good":
          return "/assets/img/icon-good.svg";
        default:
          return "";
      }
    },
    getBackgroundColour: function () {
      switch (this.alertLevel) {
        case "danger":
          return "#FBDDDD";
        case "warning":
          return "#F7EACB";
        case "good":
          return "#CDE5F6";
        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#indicator {
  @extend .flex-row;

  padding-left: $PADDING;
  padding-right: $PADDING;
  padding-top: $PADDING_X05;
  padding-bottom: $PADDING_X05;

  border-radius: 40px;
}
</style>
