<template>
  <vue3-html2pdf
    :show-layout="false"
    :enable-download="false"
    :preview-modal="true"
    :paginate-elements-by-height="1400"
    filename="stock_sheet"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <div style="padding: 24px" v-if="form">
        <div class="header">
          <p class="flex-1" style="margin: auto; text-align: center">PRE-OWNED VEHICLE STOCK RECORD</p>
          <p class="bordered input flex-1">STOCK NO:</p>
          <p class="bordered input flex-1">DATE IN STOCK:</p>
          <img src="/assets/logo-yellow.svg" class="logo" />
        </div>

        <div>
          <table>
            <tr>
              <th>Vehicle Details</th>
              <th>Trade In Details</th>
            </tr>
            <tr>
              <td>Make</td>
              <td class="form-details">{{ form.car_make }}</td>
              <td>Stock Number</td>
              <td></td>
            </tr>
            <tr>
              <td>Model / Body</td>
              <td class="form-details">{{ form.car_model }} / {{ form.car_body }}</td>
              <td>Rego No</td>
              <td class="form-details"></td>
            </tr>
            <tr>
              <td>Chassis Number</td>
              <td class="form-details">{{ form.car_chassis_number }}</td>
              <td>Make</td>
              <td class="form-details"></td>
            </tr>
            <tr>
              <td>Engine Number</td>
              <td class="form-details">{{ form.car_engine_number }}</td>
              <td>Model</td>
              <td class="form-details"></td>
            </tr>
            <tr>
              <td>Odometre Reading</td>
              <td class="form-details"></td>
              <td>Window Price</td>
              <td></td>
            </tr>
            <tr>
              <td>Colour</td>
              <td class="form-details">{{ form.car_colour }}</td>
              <td>Trade In Allowance</td>
              <td></td>
            </tr>
            <tr>
              <td>Build Date</td>
              <td class="form-details"></td>
              <td>Appraised Value</td>
              <td class="form-details"></td>
            </tr>
            <tr>
              <td>Compliance Date</td>
              <td class="form-details"></td>
              <td>O/Allowance</td>
              <td></td>
            </tr>
            <tr>
              <td>Rego No</td>
              <td class="form-details">{{ form.car_registration_number }}</td>
              <td>Payout Trade</td>
              <td>Amount $</td>
            </tr>
            <tr>
              <td>Rego Expiry</td>
              <td class="form-details">{{ getDate(form.car_registration_expiry) }}</td>
              <td>Encumbered To</td>
              <td class="form-details">{{ form.financier }}</td>
            </tr>
            <tr>
              <td>New Rego No:</td>
              <td></td>
            </tr>
            <tr>
              <td>Extras On Vehicles</td>
            </tr>
            <tr>
              <td style="height: 20px"></td>
            </tr>
            <tr>
              <td style="height: 20px"></td>
            </tr>
            <tr>
              <td>Registered Owner Full Name / Address</td>
              <td class="narrow">Check List</td>
            </tr>
            <tr>
              <td class="form-details">
                {{ form.customer_name }}, {{ form.customer_work_phone }}, {{ form.customer_home_phone }},
                {{ form.customer_email }}
              </td>
              <td class="narrow">DM</td>
            </tr>
            <tr>
              <td class="form-details">{{ form.customer_address }}</td>
              <td class="narrow">MS</td>
            </tr>
          </table>
        </div>
        <div class="vertical-spacing-top">
          <p>CUSTOMER DETAILS</p>
          <div class="flex-row">
            <p>COMPANY/TRADING NAME:</p>
            <div class="underlined flex-1"></div>
          </div>
          <div class="flex-row">
            <p>CUSTOMER NAME:</p>
            <div class="underlined flex-1 form-details"></div>
          </div>
          <div class="flex-row">
            <p>STREET:</p>
            <div class="underlined flex-1 form-details"></div>
          </div>
          <div class="flex-row">
            <p>SUBURB:</p>
            <div class="underlined flex-1"></div>
            <p>POSTCODE:</p>
            <div class="underlined flex-1"></div>
          </div>
          <div class="flex-row">
            <p>PHONE:</p>
            <div class="underlined flex-1 form-details"></div>
            <p>MOBILE:</p>
            <div class="underlined flex-1 form-details"></div>
          </div>
          <div class="flex-row">
            <p>EMAIL:</p>
            <div class="underlined flex-1 form-details"></div>
          </div>
        </div>
        <div class="flex-row">
          <div class="flex-1">
            <table>
              <tr>
                <td>CONTENTS CHECKLIST</td>
                <td class="narrow">SALES PERSON</td>
                <td class="narrow">SALES MANAGER</td>
              </tr>
              <tr>
                <td>CONTENTS CHECKLIST</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>TAX INVOICE</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>REGISTRATION/TRANSFER FORM</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>INTERNAL VEHICLE CHECK SHEET</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>AFTERMARKET / FINANCE IN DEAL</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>CAR HISTORY ON SOLD VEHICLE</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>PPSR ON TRADE VEHICLE</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>TRADE IN TAX INVOICE - BUSINESS</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>VENDOR STATEMENT</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>APPRAISAL</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
              <tr>
                <td>CORRECT DATA IN CRM</td>
                <td class="narrow"></td>
                <td class="narrow"></td>
              </tr>
            </table>
          </div>
          <div class="html2pdf__page-break"></div>
          <div class="flex-1" style="margin-left: 12px">
            <table>
              <tr>
                <td>SELLING PRICE</td>
                <td></td>
              </tr>
              <tr>
                <td>SOLD KILOMETERS</td>
                <td></td>
              </tr>
            </table>
            <table>
              <tr>
                <td>SAP CHECK</td>
              </tr>
              <tr>
                <td>AIRBAG RECALL CHECK</td>
              </tr>
              <tr>
                <td>ACQUIRED DATE</td>
              </tr>
              <tr>
                <td>CTP SUPPLIER AND CLASS</td>
              </tr>
            </table>
            <table>
              <tr>
                <td>VEHICLE TAKEN OFF INTERNET:</td>
              </tr>
              <tr>
                <td>ONSOLD WITH MANUFACTURER</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="flex-row vertical-spacing-top">
          <p>SALESPERSON:</p>
          <div class="underlined flex-1 form-details"></div>
          <p>DATE:</p>
          <div class="underlined flex-1 form-details"></div>
        </div>
      </div>
    </template>
  </vue3-html2pdf>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import moment from "moment";

export default {
  name: "StockCarForm",
  props: ["form"],
  components: { Vue3Html2pdf },
  methods: {
    download: function () {
      this.$refs.html2Pdf.generatePdf();
    },
    getDateMonthYear: function (inputDate) {
      if (inputDate) {
        let dateAsDate = new Date(inputDate);
        if (dateAsDate && !isNaN(dateAsDate.getTime())) {
          return moment(dateAsDate).format("MMM-YYYY");
        }
      }
      return null;
    },
    getDate: function (inputDate) {
      if (inputDate) {
        let dateAsDate = new Date(inputDate);
        if (dateAsDate && !isNaN(dateAsDate.getTime())) {
          return moment(dateAsDate).format("DD-MMM-YYYY");
        }
      }
      return null;
    },
    getOfferValue: function (offer) {
      if (offer) {
        if (offer.adjusted_value) {
          return "$" + offer.adjusted_value;
        }
        return "$" + offer.price;
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0px;
  margin: 0px;
}

p {
  font-size: 13px;
}

.logo {
  height: 40px;
  width: 40px;
  margin: auto;
  margin-left: 12px;
}

.header {
  display: flex;

  .input {
    padding-bottom: 24px;
  }
}

.flex-1 {
  flex-grow: 1;
  flex-basis: 0;
}

.underlined {
  border-bottom: 1px solid black;
  padding-left: 8px;
}

table {
  width: 100%;
  margin-top: 12px;
  empty-cells: show;
}

tr {
  display: flex;

  .narrow {
    max-width: 18% !important;
  }
}

td,
th {
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 2px;

  font-size: 13px;

  border: 1px solid black;
}

.bordered {
  border: 2px solid black;
}

.vertical-spacing-top {
  margin-top: 12px;
}

.form-details {
  color: darkred;
  font-weight: bold;
}
</style>
