<template>
  <base-modal-view title="Edit Offer" @cancel="cancelClicked">
    <div>
      <form @submit.prevent="saveClicked" class="horizontal-spacing flex-column">
        <p class="text-field-title margin-top margin-bottom-025">Price (in dollars)</p>
        <input
          required
          type="number"
          v-model="adjustmentDict.price"
          step="1"
          class="text-field"
          placeholder="Price (in dollars)"
        />
        <div class="flex-column" v-if="currentReseller">
          <p class="text-field-title margin-top margin-bottom-025">Private Price (in dollars)</p>
          <input
            type="number"
            v-model="adjustmentDict.private_price"
            class="text-field"
            placeholder="Private Price (in dollars)"
          />
        </div>
        <div v-if="showTraded && currentDealer" class="flex-row margin-top-075 text-field-title">
          <label class="margin-left-auto" for="final">Final</label>
          <input v-model="final" class="margin-left-05" type="checkbox" id="final" />
          <label class="margin-left-05" for="traded">Traded</label>
          <input v-model="traded" class="margin-left-05" type="checkbox" id="traded" />
          <div class="spacer margin-left margin-right"></div>
          <label for="archive">Archive</label>
          <input v-model="archive" class="margin-left-05" type="checkbox" id="archive" />
        </div>

        <p v-if="!currentDealer" class="text-field-title margin-top margin-bottom-025">Comments</p>
        <input
          v-if="!currentDealer"
          type="text"
          v-model="adjustmentDict.comments"
          class="text-field"
          placeholder="Comments"
        />
        <primary-button type="submit" :disabled="!validFields()" class="margin-top" title="Save" />
      </form>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapState } from "vuex";

export default {
  name: "AdjustOfferModal",
  components: { PrimaryButton, BaseModalView },
  props: ["offer", "showTraded"],
  data: function () {
    return {
      adjustmentDict: {
        price: this.offer.price,
        private_price: this.offer.private_price,
        comments: this.offer.comments
      },
      archive: false,
      traded: false,
      final: false
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    saveClicked: function () {
      if (!this.validFields()) {
        return;
      }
      if (this.currentDealer) {
        this.$emit("onSave", {
          adjusted_value: this.adjustmentDict.price,
          dealer: this.currentDealer.url,
          archive: this.archive,
          final: this.final,
          traded: this.traded
        });
      } else {
        this.$emit("onSave", this.adjustmentDict);
      }
    },
    validFields: function () {
      return this.adjustmentDict.price;
    }
  },
  computed: {
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentReseller: state => state.resellerStore.currentReseller
    })
  },
  watch: {
    traded: function (newVal) {
      if (newVal) {
        this.final = newVal;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";

.spacer {
  background-color: $DIVIDER_GRAY_COLOR;
  border-left: 1px solid $DIVIDER_GRAY_COLOR;
}
</style>
