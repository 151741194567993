<template>
  <base-modal-view v-if="offsiteForm" :title="offsiteForm.customer_name" @cancel="cancelClicked">
    <label class="text-field-title margin-bottom-025 margin-top">Email:</label>
    <p class="text-field">{{ offsiteForm.customer_email }}</p>
    <label class="text-field-title margin-bottom-025 margin-top">Phone:</label>
    <p class="text-field">{{ offsiteForm.customer_work_phone }}</p>
    <div class="flex-column" v-if="offsiteForm.kadabra_conversation">
      <KadabraConversationView :conversation="offsiteForm.kadabra_conversation" />
    </div>
  </base-modal-view>
</template>

<script>
import BaseModalView from "@/components/Modals/v2/BaseModalView.vue";
import KadabraConversationView from "./KadabraConversationView.vue";

export default {
  name: "OffsiteFormConversationModal",
  components: { BaseModalView, KadabraConversationView },
  props: {
    offsiteForm: {
      type: Object,
      required: true
    }
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
