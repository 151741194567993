<template>
  <base-modal-view v-if="upgradeInterest" title="Customer Interest" @cancel="cancelClicked">
    <label class="text-field-title margin-bottom-025 margin-top">Customer wants to sell:</label>
    <p class="text-field">{{ upgradeInterest.sell ? 'Yes' : 'No' }}</p>
    <label class="text-field-title margin-bottom-025 margin-top">Customer wants to upgrade:</label>
    <p class="text-field">{{ upgradeInterest.upgrade ? 'Yes' : 'No' }}</p>
    <div class="flex-column" v-if="upgradeInterest.messages">
      <KadabraConversationView :conversation="{'messages': upgradeInterest.messages}" />
    </div>
  </base-modal-view>
</template>

<script>
import BaseModalView from "@/components/Modals/v2/BaseModalView.vue";
import KadabraConversationView from "../Offsites/Modals/KadabraConversationView.vue";

export default {
  name: "UpgradeInterestModal",
  components: { BaseModalView, KadabraConversationView },
  props: {
    upgradeInterest: {
      type: Object,
      required: true
    }
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
