<template>
  <base-modal-view title="Select Tender" @cancel="cancelClicked">
    <h6 class="body-2 margin-top" v-if="tenders.length <= 0">
      There don't seem to be any pending tenders coming up. Appraisals can only be added to Tenders before they start.
    </h6>
    <table v-if="tenders.length > 0" class="results-table margin-top">
      <tr class="header">
        <th>Title</th>
        <th>Start Time</th>
        <th>End Time</th>
      </tr>
      <tr
        v-for="tender in tenders"
        v-bind:key="tender.id"
        class="selectable data-row"
        v-on:click="selectedTender(tender)"
      >
        <td>{{ tender.title }}</td>
        <td>{{ formatDate(tender.start_time) }}</td>
        <td>{{ formatDate(tender.end_time) }}</td>
      </tr>
    </table>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import { fetchPendingTenders } from "../../../api/Tender.js";
import { mapActions } from "vuex";

export default {
  name: "TenderListModal",
  components: { BaseModalView },
  props: ["tender", "forCSVUpload"],
  data: function () {
    return {
      tenders: []
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    formatDate(dateString) {
      let convertedDate = new Date(dateString);
      return convertedDate.toDateString() + ", " + convertedDate.toLocaleTimeString();
    },
    selectedTender(tender) {
      this.$emit("submit", tender.url);
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  mounted: function () {
    fetchPendingTenders()
      .then(response => {
        this.tenders = response;
      })
      .catch(error => {
        this.addError(error);
      });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
