<template>
  <div>
    <div class="flex-row">
      <div
        id="indicator"
        class="vertical-flex-center"
        :style="{ backgroundColor: getBackgroundColour(), border: getBorder() }"
      ></div>
      <p class="h3-subtitle margin-left-05">{{ alertTitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusIndicator",
  props: {
    alertLevel: {
      type: String,
      default: "archived"
    },
    alertTitle: {
      type: String,
      default: ""
    }
  },
  methods: {
    getBackgroundColour: function () {
      switch (this.alertLevel) {
        case "archived":
          return "#D9D9D9";
        case "not_traded":
          return "";
        case "traded":
          return "#27A1FF";
        case "lost":
          return "#BC2C2C";
        case "won":
          return "#27A1FF";
        case "pending":
          return "#FFBF26";
        default:
          return "";
      }
    },
    getBorder: function () {
      switch (this.alertLevel) {
        case "not_traded":
          return "1px solid #606060";
        default:
          return "none";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#indicator {
  height: 16px;
  width: 16px;
  border-radius: 16px;
}
</style>
