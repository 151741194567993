<template>
  <div>
    <label class="text-field-title margin-bottom-025 margin-top">Conversation:</label>
    <p
        v-for="message in sortedConversation"
        v-bind:key="message.id"
        :class="{ 'assistant-message': message.agent == 'assistant', 'customer-message': message.agent == 'user' }">
        {{ message.message }}
      </p>
  </div>
</template>

<script>
export default {
  name: "KadabraConversationView",
  props: {
    conversation: {
      type: Object,
      required: true
    }
  },
  computed: {
    sortedConversation: function () {
      let copy = { ...this.conversation };
      return copy.messages.sort((a, b) => a.id - b.id);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.assistant-message {
  @extend .body-2;
  background-color: $ORANGE_COLOR;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 60%;
}

.customer-message {
  @extend .body-2;
  background-color: $BLUE_COLOR;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 60%;
  margin-left: auto;
}
</style>
