<template>
  <base-modal-view title="Add Offer" :wide="true" @cancel="cancelClicked">
    <div class="flex-row">
      <div class="flex-grow margin-top">
        <p class="label no-margin margin-top">Offer Type</p>
        <div class="flex-row margin-top-05">
          <input
            class="margin-right-05 radio-field"
            type="radio"
            name="offer_type"
            id="custom"
            value="custom"
            v-model="radio"
          />
          <label class="body-2 vertical-flex-center" for="custom">Custom Offer</label>
        </div>
        <div class="flex-row margin-top-05">
          <input
            class="margin-right-05 radio-field"
            type="radio"
            name="offer_type"
            id="favourite"
            value="favourite"
            v-model="radio"
          />
          <label for="favourite" class="body-2 vertical-flex-center">Favourite Wholesaler</label>
        </div>
        <div v-if="radio == 'custom'">
          <form class="flex-column" @submit.prevent="submitClicked">
            <label class="text-field-title margin-top-075 margin-bottom-025">Offer Maker's Name</label>
            <input required v-model="resellerName" class="text-field" type="text" placeholder="Offer Maker's Name" />
            <label class="text-field-title margin-top-075 margin-bottom-025">Value</label>
            <div class="vert-spacing flex-row">
              <input required v-model="offerValue" class="text-field flex-grow" type="number" placeholder="Value" />
            </div>
            <div class="flex-row margin-top-075 text-field-title">
              <div v-if="currentDealer.is_super_manager" class="flex-row margin-left-auto">
                <label for="director">Director Only</label>
                <input
                  v-model="is_super_manager_only"
                  class="margin-left-05"
                  type="checkbox"
                  id="is_super_manager_only"
                />
                <div class="spacer margin-left margin-right"></div>
              </div>
              <label
                :class="{
                  'margin-left-auto': !currentDealer.is_super_manager,
                  'margin-left-05': currentDealer.is_super_manager
                }"
                for="final"
                >Final</label
              >
              <input v-model="final" class="margin-left-05" type="checkbox" id="final" />
              <label class="margin-left-05" for="traded">Traded</label>
              <input v-model="traded" class="margin-left-05" type="checkbox" id="traded" />
              <div class="spacer margin-left margin-right"></div>
              <label for="archive">Archive (DMS)</label>
              <input v-model="archive" class="margin-left-05" type="checkbox" id="archive" />
            </div>
            <primary-button class="margin-top" title="Add" type="submit" />
          </form>
        </div>
        <div v-if="radio == 'favourite'">
          <form class="flex-column margin-top" @submit.prevent="submitClicked">
            <select required v-model="selectedFavourites" multiple class="vert-spacing selector-style" name="" id="">
              <option
                class="padding-vertical-05 padding-horizontal"
                v-for="reseller in getAvailableResellers()"
                v-bind:key="reseller.url"
                :value="reseller.url"
              >
                {{ reseller.first_name }} {{ reseller.last_name }}
              </option>
            </select>
            <primary-button
              title="Select All"
              type="button"
              class="margin-top margin-left-auto"
              v-on:click="selectAllResellers()"
            />
            <primary-button title="Add" type="submit" class="margin-top" />
          </form>
        </div>
      </div>
      <div class="vertical-seperator"></div>
      <div class="flex-grow margin-left">
        <slot> </slot>
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "NewOfferModal",
  components: { PrimaryButton, BaseModalView },
  props: {
    existingOffers: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data: function () {
    return {
      radio: "custom",
      selectedFavourites: [],
      resellerName: "",
      is_super_manager_only: false,
      offerValue: null,
      traded: false,
      final: false,
      archive: false
    };
  },
  methods: {
    submitClicked: function () {
      if (this.radio == "custom") {
        this.$emit("submitCustom", {
          is_super_manager_only: this.is_super_manager_only,
          custom_name: this.resellerName,
          price: this.offerValue,
          traded: this.traded,
          final: this.final,
          archive: this.archive
        });
      } else {
        this.$emit("submitFavourite", this.selectedFavourites);
      }
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    getAvailableResellers: function () {
      return this.favouriteResellers.filter(reseller => {
        return !this.existingOffers.find(offer => {
          if (offer.reseller) {
            if (typeof offer.reseller == "string") {
              return offer.reseller == reseller.url;
            }
            return offer.reseller.url == reseller.url;
          }
          return false;
        });
      });
    },
    selectAllResellers: function () {
      this.selectedFavourites = this.getAvailableResellers().map(reseller => reseller.url);
    }
  },
  computed: {
    ...mapGetters({
      favouriteResellers: "dealershipStore/favouriteResellers"
    }),
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer
    })
  },
  watch: {
    traded: function (newVal) {
      if (newVal) {
        this.final = newVal;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

.selector-style {
  @extend .body-2;

  padding: $PADDING_X05;
  background-color: transparent;
  max-height: 300px;
}

.vertical-seperator {
  @extend .margin-left;
  @extend .margin-right;
  background-color: $CARD_BACKGROUND_COLOR;
  width: 8px;
}

.spacer {
  background-color: $DIVIDER_GRAY_COLOR;
  border-left: 1px solid $DIVIDER_GRAY_COLOR;
}
</style>
