import { axiosInstance } from "./axiosHandler";
// import store from "../store/index.js";

function adjustOffer(offer, offerDict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(offer.url, offerDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchOfferHistory(offer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(offer.url + "history/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export { adjustOffer, fetchOfferHistory };
