import { axiosInstance, endpoints } from "./axiosHandler";

function fetchTenders(filters, cancelToken) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.tender, {
        params: filters,
        cancelToken: cancelToken.token
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchPendingTenders() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.tender + "fetch-pending/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchTender(id) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.tender + id + "/", null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateTender(id, tenderDict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(endpoints.tender + id + "/", tenderDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function createTenderFromCSV(tenderDict) {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries(tenderDict)) {
      formData.append(key, value);
    }

    axiosInstance()
      .post(endpoints.tender + "upload-csv/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export { fetchTenders, fetchTender, updateTender, createTenderFromCSV, fetchPendingTenders };
