<template>
  <base-modal-view title="Suspected Odometer Rollback" @cancel="cancelClicked">
    <p class="margin-top">Previous Odometer Readings</p>
    <table class="results-table margin-top-025">
      <tr class="header">
        <th>Date</th>
        <th>Odometer Reading</th>
      </tr>
      <tr class="data-row" v-for="form in forms" v-bind:key="form">
        <td>{{ dateToDayMonthYearTimeReadableString(form.date_time_created) }}</td>
        <td>{{ form.car_mileage }}km</td>
      </tr>
    </table>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import { dateToDayMonthYearTimeReadableString } from "../../../helpers/v2/utilityHelpers";

export default {
  name: "OdometerRollbackModal",
  components: { BaseModalView },
  props: ["forms"],
  methods: {
    dateToDayMonthYearTimeReadableString: function (date) {
      return dateToDayMonthYearTimeReadableString(date);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
