<template>
  <vue3-html2pdf
    :show-layout="false"
    :enable-download="false"
    :preview-modal="true"
    :paginate-elements-by-height="1400"
    filename="vendor_statement"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <div style="padding: 24px" v-if="form">
        <div class="header margin-bottom-05">
          <h4 class="header-text">VENDOR'S STATEMENT TO MOTOR DEALER</h4>
        </div>
        <div class="flex-row">
          <div class="flex-column margin-right-05">
            <div class="padding-025 bordered margin-bottom-05">
              <b>Staff Name:</b>
              <p v-if="form.starting_dealer">
                {{ form.starting_dealer.first_name }} {{ form.starting_dealer.last_name }}
              </p>
              <p v-if="!form.starting_dealer">-</p>
            </div>
            <div class="padding-025 bordered flex-grow">
              <b>Date:</b>
              <p>{{ getDate(form.date_time_created) }}</p>
            </div>
          </div>
          <div class="flex-column bordered margin-right-05 flex-grow">
            <h4 class="header header-text-small">LICENCED MOTOR DEALER</h4>
            <p class="center-text header-text-smaller margin-auto">{{ form.dealership.name }}</p>
            <div class="flex-row margin-bottom-05">
              <div class="flex-grow margin-left-05">ABN:</div>
              <div class="separator margin-right-05"></div>
              <div class="flex-grow">Dealer Licence:</div>
            </div>
          </div>
          <div class="flex-column">
            <div class="padding-025 bordered margin-bottom-05">
              <b>Evaluation Ref #:</b>
              <p>{{ form.id }}</p>
            </div>
            <div class="padding-025 bordered flex-grow">
              <b>Internal Ref #:</b>
              <p></p>
            </div>
          </div>
        </div>
        <div class="flex-row margin-bottom-05">
          <table class="flex-grow">
            <tr>
              <td>Vendor</td>
              <td>
                <b>{{ form.customer_name }}</b>
              </td>
              <td></td>
              <td>ABN/ACN</td>
            </tr>
            <tr>
              <td>Address</td>
              <td></td>
              <td></td>
              <td>Suburb</td>
              <td>State</td>
              <td>P/Code</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                <b>{{ form.customer_work_phone }}</b>
              </td>
              <td>Email</td>
              <td>
                <b>{{ form.customer_email }}</b>
              </td>
            </tr>
            <tr>
              <td>Vendor Name (1)</td>
              <td></td>
              <td></td>
              <td>Licence # (1)</td>
              <td>D.O.B (1)</td>
            </tr>
            <tr>
              <td>Vendor Name (2)</td>
              <td></td>
              <td></td>
              <td>Licence # (2)</td>
              <td>D.O.B (2)</td>
            </tr>
          </table>
        </div>
        <div class="margin-top-05 flex-column">
          <div class="header">
            <h4 class="header-text">Motor Dealers and Chattel Auctioneers Acto 2014 Section 73</h4>
            <p class="header-text-small">DESCRIPTION OF USED MOTOR VEHICLE (OR THE PURCHASE / TRADE-IN)</p>
          </div>
          <div class="flex-grow flex-column">
            <table class="flex-grow">
              <tr>
                <td>
                  Year <b>{{ form.car_model_year }}</b>
                </td>
                <td>
                  Make <b>{{ form.car_make }}</b>
                </td>
                <td>
                  Model <b>{{ form.car_model }}</b>
                </td>
                <td>
                  Variant <b>{{ form.car_line }}</b>
                </td>
              </tr>
            </table>
            <table class="flex-grow">
              <tr>
                <td>
                  Body Type <b>{{ form.car_body }}</b>
                </td>
                <td>
                  Body Colour <b>{{ getPPSRProperty("car_colour") }}</b>
                </td>
                <td>
                  Trim <b>{{ form.car_trim }}</b>
                </td>
              </tr>
            </table>
            <table class="flex-grow">
              <tr>
                <td>Transmission</td>
                <td>
                  Fuel Type
                  <b v-if="form.car_fuel">{{ getEngineString() }}</b>
                  <b v-if="!form.car_fuel">-</b>
                </td>
                <td>
                  Engine Size
                  <b v-if="form.car_fuel">{{ getEngineSizeString() }}</b>
                  <b v-if="!form.car_fuel">-</b>
                </td>
                <td>Cylinders</td>
              </tr>
            </table>
            <table class="flex-grow">
              <tr>
                <td>
                  Build <b>{{ getDateMonthYear(form.car_build_date) }}</b>
                </td>
                <td>
                  Compliance <b>{{ getDateMonthYear(getPPSRProperty("car_compliance_date")) }}</b>
                </td>
                <td>
                  VIN <b>{{ getPPSRProperty("car_chassis_number") }}</b>
                </td>
                <td>
                  Engine # <b>{{ getPPSRProperty("car_engine_number") }}</b>
                </td>
              </tr>
            </table>
            <table class="flex-grow">
              <tr>
                <td>
                  Odometer <b>{{ form.car_mileage }}</b>
                </td>
                <td>
                  Odometer in Words <b>{{ convertNumberToEnglish(form.car_mileage) }}</b>
                </td>
              </tr>
            </table>
            <table class="flex-grow">
              <tr>
                <td>
                  Reg Plate <b>{{ getPPSRProperty("car_registration_number") }}</b>
                </td>
                <td>
                  Reg Expry
                  <b>{{ getDate(getPPSRProperty("car_registration_expiry")) }}</b>
                </td>
                <td>Personal Plates? <b>YES / NO</b></td>
                <td>New Reg Plate</td>
              </tr>
            </table>
          </div>
          <div class="header margin-bottom-05">
            <h4 class="header-text">Statement</h4>
          </div>
          <div class="flex-row">
            <p>
              <b style="display: inline">I / We</b> have this day sold the vehicle described above to the motor dealer
              shown, together with all accessories (as listed below) for the sum of (inclusive of GST)
            </p>
            <div class="flex-grow write-here"></div>
          </div>
          <b>Accessories</b>
          <ol class="margin-bottom">
            <li>
              a) The engine <b> HAS / HAS NOT </b> been replaced by/on behalf of the vendor. If replaced the approximate
              date of replacement was ....... /....... / ....... (dd/mm/yy) <br />
              b) The Odometer <b> HAS / HAS NOT </b> been replaced by/on behalf of the vendor, and I confirm to the best
              of my knowledge that the odometer reading is correct.<br />
              c) The concessional Registration <b> HAS / HAS NOT </b> been claimed in respect of the vehicle by the
              vendor.<br />
              d) If fitted with Air Bags, have the Air Bags been activated? <b> YES / NO </b><br />
              e) That any conversion or modification from the manufacturer's specifications <b> HAS / HAS NOT </b> been
              approved by the Qld Transport Department (or the Relevant State Transport Authority), and a Letter of
              Approval is held and modification plates are fixed to the vehicle.<br />
              f) This vehicle <b> IS / IS NOT </b> on the written off register<br />
              g) This vehicle <b> HAS / HAS NOT </b> previously been on the written of register<br />
            </li>
            <li>
              a) The vehicle is the vendor's sole and absolutely unencumbered property and free from any Consumer
              Mortgage, Bill of Sale, Hire Purchase/Agreement, lien charge or an adverse interest whatsoever, and no
              person or corporation has any rights title or interest therein and the vendor has good right and title to
              sell same; <b> or </b> <br />
              b) There is an encumbrance, charge, lien, or the other adverse interest whatsoever by the way or
              <b> Bill of Sale / Hire Purchase Agreement / Other </b> over the said vehicle is to for the amount of
              (Inclusive of GST) <br />
              c) The said encumbrance <b> IS / IS NOT </b> to be paid out by the Motor Dealer. <br />
            </li>
            <li>I am not bankrupt and have not committed any act of bankruptcy.</li>
            <li>The vehicle <b> HAS / HAS NOT </b> been used as a Taxi or Hire Car.</li>
            <li>That no Defect notice has been issued against this vehicle.</li>
            <li>
              The vehicle <b> IS/ IS NOT </b> to be sold at auction. The reserve price for the vehicle at auction is
              $..................................... (delete as applicable)
            </li>
            <li>
              In the interpretation of the document, the singular shall include the plural and any statement or warranty
              by or obligation upon more than one person shall bind them jointly and severally.
            </li>
            <li>
              <b> Goods and Services Tax (GST) Statement. </b> <br />
              (i) I am registered or required to be registered for GST - <b> YES / NO </b> <br />
              (ii) The supply of the above mentioned vehicle by me represented a taxable supply by me in the course of
              an enterprise which I carry on - <b> YES / NO </b> <br />
              (iii) The supply of the above mentioned vehicle by me is not a GST-free supply - <b> YES / NO </b> <br />
              (iv) If this supply does represent a taxable supply by me. I will provide the Dealer with a valid
              tax/invoice at the time of Dealer signing this statement. <br />
            </li>
            <li>
              <b>
                I understand that under section 576 of the Motor Dealers and Chattel Auctioneers Act 2014, I am liable
                to penalty if I willfully represent anything that is false or misleading about the total distance
                traveled by the above mentioned vehicle.
              </b>
            </li>
          </ol>
          <div class="flex-row margin-right-05 margin-bottom">
            <b>Declared On:</b>
            <p>{{ getDate(new Date()) }}</p>
          </div>
          <div class="margin-top-auto">
            <div class="border-top flex-row signature-spacer">
              <b>Vender ("Seller")</b>
              <b class="margin-left-auto">Witness</b>
            </div>
          </div>
        </div>
      </div>
    </template>
  </vue3-html2pdf>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import moment from "moment";

export default {
  name: "VendorStatement",
  props: ["form"],
  components: { Vue3Html2pdf },
  methods: {
    download: function () {
      this.$refs.html2Pdf.generatePdf();
    },
    getDateMonthYear: function (inputDate) {
      if (inputDate) {
        let dateAsDate = new Date(inputDate);
        if (dateAsDate && !isNaN(dateAsDate.getTime())) {
          return moment(dateAsDate).format("MMM-YYYY");
        }
      }
      return null;
    },
    getDate: function (inputDate) {
      if (inputDate) {
        let dateAsDate = new Date(inputDate);
        if (dateAsDate && !isNaN(dateAsDate.getTime())) {
          return moment(dateAsDate).format("DD-MMM-YYYY");
        }
      }
      return null;
    },
    convertNumberToEnglish: function (n) {
      if (!n) {
        return "";
      }
      if (n < 0) {
        return "";
      }

      if (n === 0) return "Zero";
      var result = this.translate(n);
      return result.trim() + ".";
    },
    translate: function (n) {
      let single_digit = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
      let double_digit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen"
      ];
      let below_hundred = ["Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

      var word = "";
      if (n < 10) {
        word = single_digit[n] + " ";
      } else if (n < 20) {
        word = double_digit[n - 10] + " ";
      } else if (n < 100) {
        var rem = this.translate(n % 10);
        word = below_hundred[(n - (n % 10)) / 10 - 2] + " " + rem;
      } else if (n < 1000) {
        word = single_digit[Math.trunc(n / 100)] + " Hundred " + this.translate(n % 100);
      } else if (n < 1000000) {
        word = this.translate(parseInt(n / 1000)).trim() + " Thousand " + this.translate(n % 1000);
      } else if (n < 1000000000) {
        word = this.translate(parseInt(n / 1000000)).trim() + " Million " + this.translate(n % 1000000);
      } else {
        word = this.translate(parseInt(n / 1000000000)).trim() + " Billion " + this.translate(n % 1000000000);
      }
      return word;
    },
    getPPSRProperty: function (property) {
      if (this.form[property]) {
        return this.form[property];
      }
      if (this.form.ppsr) {
        return this.form.ppsr[property];
      }
      return "";
    },
    getEngineString: function () {
      let index = this.form.car_fuel.indexOf(",");
      if (index) {
        if (index > 1) {
          return this.form.car_fuel.substring(0, index - 1);
        }
      }
      return this.form.car_fuel;
    },
    getEngineSizeString: function () {
      let index = this.form.car_fuel.indexOf(",");
      if (index > 0) {
        if (index < this.form.car_fuel.length - 1) {
          return this.form.car_fuel.substring(index - 1, this.form.car_fuel.length);
        }
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0px;
  margin: 0px;
  font-size: 9px;
}

.header {
  color: white;
  background-color: #0b80f6;
  padding: 4px;
  text-align: center;
}

.header-text {
  font-size: 2em;
}

.header-text-small {
  font-size: 1.5em;
}

.header-text-smaller {
  font-size: 1.2em;
}

.margin-bottom-05 {
  margin-bottom: 8px;
}

.margin-bottom {
  margin-bottom: 16px;
}

.margin-right-05 {
  margin-right: 8px;
}

.margin-left-05 {
  margin-left: 8px;
}

.padding-025 {
  padding: 4px;
}

.flex-row {
  display: flex;
  direction: row;
}

.flex-column {
  display: flex;
  direction: column;
}

.flex-grow {
  flex-grow: 1;
  flex-basis: 0;
}

.separator {
  width: 1px;
  background: black;
}

.bordered {
  border: 1px solid rgba($color: #0b80f6, $alpha: 0.6);
}

.center-text {
  text-align: center;
}

.margin-auto {
  margin: auto;
}

.margin-top-auto {
  margin-top: auto;
}

.border-top {
  border-top: 1px dotted black;
}

.margin-left-auto {
  margin-left: auto;
}

tr {
  border-bottom: 1px solid black;
}

td {
  padding-bottom: 4px;
  padding-top: 12px;
}

.bold {
  font-weight: bold;
}

.write-here {
  height: 1;
  border-bottom: 1px solid black;
}

ol {
  counter-reset: item;
}

.signature-spacer {
  margin-top: 48px;
}
</style>
