<template>
  <button id="btn" :class="{ disabled: disabled, selected: selected }">
    <div class="flex-row body-2-bold">
      <div class="flex-row flex-center">
        <img :src="icon" v-if="icon" />
        <p>{{ title }}</p>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "TertiaryButton",
  props: ["title", "icon", "disabled", "selected"]
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#btn {
  padding: 11px 10px;
  color: $ACTION_COLOR;
  background-color: white;
  border: none;
  cursor: pointer;
  height: 40px;

  &:hover {
    color: $ACTION_COLOR_HOVER;
  }

  img {
    margin-right: $PADDING_X05;
  }
}

.disabled {
  color: $ACTION_COLOR_INACTIVE !important;
}

.selected {
  background-color: $YELLOW_COLOR !important;
}
</style>
