<template>
  <base-modal-view title="Offer History" auto-width="true" @cancel="cancelClicked">
    <div>
      <table class="results-table">
        <tr class="header">
          <td>Updated</td>
          <td>Offer</td>
          <td>Wholesaler</td>
          <td>Adjustment</td>
          <td>User</td>
        </tr>
        <tr class="data-row" v-for="offerHistory in history" v-bind:key="offerHistory.url">
          <td>{{ dateToDayMonthYearTimeReadableString(offerHistory.date_time_created) }}</td>
          <td>{{ offerHistory.price ? "$" + offerHistory.price : "" }}</td>
          <td>
            {{ offerHistory.reseller ? offerHistory.reseller.first_name + " " + offerHistory.reseller.last_name : "" }}
          </td>
          <td>{{ offerHistory.adjusted_value ? "$" + offerHistory.adjusted_value : "" }}</td>
          <td>{{ offerHistory.dealer ? offerHistory.dealer.first_name + " " + offerHistory.dealer.last_name : "" }}</td>
        </tr>
      </table>
      <div v-if="loading">
        <img src="/assets/img/loader.svg" alt="" class="loader" />
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import { fetchOfferHistory } from "@/api/Offer.js";
import { dateToDayMonthYearTimeReadableString } from "@/helpers/v2/utilityHelpers.js";
import { mapActions } from "vuex";

export default {
  name: "OfferHistoryModal",
  components: { BaseModalView },
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      loading: false,
      history: []
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    dateToDayMonthYearTimeReadableString: function (datetime) {
      return dateToDayMonthYearTimeReadableString(datetime);
    },
    fetchOfferHistory: function () {
      this.loading = true;
      fetchOfferHistory(this.offer)
        .then(response => {
          this.history = response;
        })
        .catch(error => {
          this.addError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  mounted: function () {
    this.fetchOfferHistory();
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
