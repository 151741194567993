<template>
  <base-modal-view title="Transfer Appraisals" @cancel="cancelClicked">
    <div class="margin-top flex-column">
      <p v-if="forms" class="text-field-title">Number of Appraisals to Transfer: {{ forms.length }}</p>
      <select class="dropdown-field margin-top-025" v-model="dealership" name="dealership" placeholder="Transfer">
        <option value="">-- Select Dealership to Transfer To --</option>
        <option v-for="dealership in dealerships" :value="dealership.id" v-bind:key="dealership.id">
          {{ dealership.first_name }}
        </option>
      </select>
      <primary-button v-on:click="submitClicked" class="margin-top" title="Submit" />
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapState } from "vuex";

export default {
  name: "SelectDealershipModal",
  props: ["forms"],
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      dealership: ""
    };
  },
  methods: {
    submitClicked: function () {
      if (this.dealership) {
        this.$emit("submit", this.dealership);
      }
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  computed: {
    ...mapState({
      dealerships: state => state.dealershipStore.dealerships
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
