<template>
  <base-modal-view title="Ready For Trade" @cancel="cancelClicked">
    <p class="text-field-title margin-top">Are you sure you want to mark this appraisal as Ready For Trade?</p>
    <form class="flex-column" @submit.prevent="submitClicked">
      <label class="text-field-title margin-top-075 margin-bottom-025">Set an ETA (optional):</label>
      <input v-model="eta" class="dropdown-field" type="date" placeholder="ETA" />
      <primary-button class="margin-top" title="Submit" />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

export default {
  name: "ReadyForTradeModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      eta: null
    };
  },
  methods: {
    submitClicked: function () {
      this.$emit("setReadyForTrade", this.eta);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
