<template>
  <base-modal-view title="Online Presence" @cancel="cancelClicked">
    <p class="margin-top">
      Online Sources - Requested at
      {{ dateToDayMonthYearTimeReadableString(form.auto_grab_listing_history.date_time_created) }}
    </p>
    <table class="results-table margin-top-025">
      <tr class="header">
        <th>Source</th>
        <th>Date</th>
        <th>Odometer</th>
        <th>Price</th>
      </tr>
      <tr class="data-row" v-for="(value, name) in form.auto_grab_listing_history.online_sources" v-bind:key="name">
        <td>{{ name }}</td>
        <td>{{ dateToDayMonthYearTimeReadableString(value.date_time_created) }}</td>
        <td>{{ value.odometer }}km</td>
        <td>${{ value.price }}</td>
      </tr>
    </table>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import { dateToDayMonthYearTimeReadableString } from "../../../helpers/v2/utilityHelpers";

export default {
  name: "OnlineSourcesModal",
  components: { BaseModalView },
  props: ["form"],
  methods: {
    dateToDayMonthYearTimeReadableString: function (date) {
      return dateToDayMonthYearTimeReadableString(date);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
