import { axiosInstance } from "./axiosHandler";

function createBodyDamage(url, dict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(url + "body-damage/", {
        description: dict.description,
        cost: dict.cost,
        is_mechanical: dict.is_mechanical
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateBodyDamage(url, dict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(url, dict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export { createBodyDamage, updateBodyDamage };
