import moment from "moment";

function timeDifferenceString(startTime, endTime) {
  const d = Number((startTime.getTime() - endTime.getTime()) / 1000);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

  return hDisplay + mDisplay + sDisplay;
}

function setStringDateToStartOfDate(dateString) {
  let date = new Date(dateString);
  if (moment(date).isValid()) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  } else {
    return null;
  }
}

function setStringDateToEndOfDate(dateString) {
  let date = new Date(dateString);
  if (moment(date).isValid()) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  } else {
    return null;
  }
}

function dateToDayMonthYearTimeReadableString(dateString) {
  if (moment(new Date(dateString))) {
    return moment(new Date(dateString))?.format("DD-MMM-YYYY hh:mm a");
  } else {
    return "N/A";
  }
}

function dateToDayMonthYearReadableString(dateString) {
  if (moment(new Date(dateString))) {
    return moment(new Date(dateString))?.format("DD-MMM-YYYY");
  } else {
    return "N/A";
  }
}

let monthArray = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function pageBounds(page) {
  return {
    lowBound: (page - 1) * 20,
    highBound: (page - 1) * 20 + 20
  };
}

export {
  timeDifferenceString,
  setStringDateToStartOfDate,
  setStringDateToEndOfDate,
  dateToDayMonthYearTimeReadableString,
  dateToDayMonthYearReadableString,
  monthArray,
  pageBounds
};
