<template>
  <base-modal-view title="Warnings" @cancel="cancelClicked">
    <table class="results-table margin-top">
      <tr class="header">
        <th>Warning</th>
        <th>Value</th>
      </tr>
      <tr class="data-row" v-for="danger in getDangers()" v-bind:key="danger">
        <td>{{ danger.title }}</td>
        <td>{{ danger.value }}</td>
        <td><img src="/assets/img/icon-danger.svg" alt="" /></td>
      </tr>
      <tr class="data-row" v-for="warning in getWarnings()" v-bind:key="warning">
        <td>{{ warning.title }}</td>
        <td>{{ warning.value }}</td>
        <td><img src="/assets/img/icon-warning.svg" alt="" /></td>
      </tr>
    </table>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import { getWarningsAndDangers } from "@/helpers/v2/formHelpers.js";

export default {
  name: "FormWarningModal",
  components: { BaseModalView },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    getWarnings: function () {
      return getWarningsAndDangers(this.form).warnings;
    },
    getDangers: function () {
      return getWarningsAndDangers(this.form).dangers;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
