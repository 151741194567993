<template>
  <base-modal-view title="Pricing" @cancel="cancelClicked">
    <div>
      <form @submit.prevent="saveClicked" class="flex-column margin-top">
        <label class="text-field-title margin-bottom-025">Send Price to Sales</label>
        <input type="number" v-model="dict.sales_value" class="text-field margin-bottom-05" placeholder="Sales Price" />
        <label class="text-field-title margin-bottom-025">Retail Price</label>
        <input
          type="number"
          v-model="dict.retail_value"
          class="text-field margin-bottom-05"
          placeholder="Retail Price"
        />
        <div class="flex-row margin-top-075 text-field-title">
          <label for="archive">Archive (DMS)</label>
          <input v-model="dict.is_archived" class="margin-left-05" type="checkbox" id="archive" />
        </div>
        <primary-button type="submit" class="margin-top" title="Save" />
      </form>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";

export default {
  name: "ReasonsLostModal",
  components: { PrimaryButton, BaseModalView },
  props: ["form"],
  data: function () {
    return {
      dict: {
        sales_value: "",
        retail_value: "",
        is_archived: false
      }
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    saveClicked: function () {
      this.$emit("onSubmit", this.dict);
    }
  },
  mounted: function () {
    this.dict.sales_value = this.form.sales_value;
    this.dict.retail_value = this.form.retail_value;
    this.dict.is_archived = this.form.is_archived;
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
